import { useState } from 'react';
import { UserData, getProductUserData, getUserProductAvailability } from '../../api/product';
import CMSSlot from '../../cms/CMSSlot';
import { useCmsContent } from '../../cms/PageData';
import ErrorBox from '../../generic/ErrorBox';
import InfoBox from '../../generic/InfoBox';
import Page from '../../generic/Page';
import { WrapTrackingContext } from '../../generic/TrackingContext';
import { getCategories } from '../../model/Product';
import ProductTools from '../../product-tools/ProductTools';
import { useOnMount } from '../../utils/useComponentDidMount';
import AddToCartAction from '../AddToCartAction';
import ProductAvailability, { Availability } from '../ProductAvailability';
import { ProductCardDetails } from '../ProductCard';
import ProductEmailReminder from '../emailreminder/ProductEmailReminder';
import ProductCharacteristics, { ProductCharacteristicsProps } from './ProductCharacteristics';
import ProductDetailsImage from './ProductDetailsImage';
import ProductDetailsTabs from './ProductDetailsTabs';
import ProductOriginLinks, { ProductArea } from './ProductOriginLinks';
import { getProductStructuredData } from './structuredProductData';

/* A lot of values are optional because of products such as gift-bags and cards*/
export interface DetailedProduct extends ProductCardDetails, ProductCharacteristicsProps, ProductArea {
    taste?: string;
    smell?: string;
    main_producer?: { name: string };
    color?: string;
    ageLimit: number;
    year: string;
    similarProducts: boolean;
    statusNotification?: boolean;
}

export interface Props {
    product: DetailedProduct;
}

export default function ProductDetailsPage({ product }: Props) {
    const [userData, setUserData] = useState<UserData | undefined>();
    const [availability, setAvailability] = useState<Availability | undefined>(product.productAvailability);

    useOnMount(({ isLoggedIn }) => {
        getUserProductAvailability(product.code).then(setAvailability);

        if (isLoggedIn) {
            getProductUserData(product.code).then(setUserData);
        }

        gtag('event', 'view_item', {
            items: [{ item_id: product.code }],
        });
    });

    const structuredData = getProductStructuredData(product);

    return (
        <>
            <Page>
                <div className="product__details">
                    <WrapTrackingContext set={{ item_id: product.code }}>
                        <div className="product__details--top">
                            <ProductTools product={product} productUserData={userData} onUserDataChange={setUserData} />
                            <ProductDetailsImage product={product} showPressImageLink zoomImage />
                            <div className="product__layout-wrapper">
                                <div className="product-details-main">
                                    <p className="product__category-name">{getCategories(product)}</p>
                                    <h1 className="product__name">{product.name}</h1>
                                    <p className="product__region">
                                        <ProductOriginLinks product={product} />
                                    </p>
                                    {!!product.taste && <p className="product__taste">{product.taste}</p>}
                                </div>
                                {product.expired ? (
                                    <div className="product-price-expired">Utgått</div>
                                ) : (
                                    <div className="product__price-buy-stock__container">
                                        <div className="product__price-and-buy">
                                            <p className="product__price-and-buy__price-wrapper">
                                                <ValueSpan value={product.price} className="product__price" />
                                                <span className="volume-and-cost_per_unit">
                                                    <ValueSpan value={product.volume} className="amount" />
                                                    <ValueSpan value={product.litrePrice} />
                                                </span>
                                            </p>
                                            <AddToCartAction code={product.code} buyable={product.buyable} maxQuantity={product.maxQuantity} />
                                        </div>
                                        <ProductAvailability product={product} availability={availability} />
                                        <ProductEmailReminder
                                            product={product}
                                            statusNotificationRegistered={userData?.statusNotificationRegistered}
                                            onUserDataChange={setUserData}
                                        />
                                        {!!product.maxQuantity && (
                                            <InfoBox
                                                message={`Dette produktet er kvotert. Du har kun mulighet til å bestille ${product.maxQuantity} flaske(r)`}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <ProductCharacteristics content={product.content} stickers={product.stickers} sustainable={product.sustainable} />
                        <div className="product__layout-wrapper-3">
                            <div className="product__all-info">
                                <ProductDetailsTabs product={product} />
                            </div>
                        </div>
                        {!!structuredData && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />}
                    </WrapTrackingContext>
                </div>
            </Page>
            <ProductCMSSlot code={product.code} />
        </>
    );
}

function ProductCMSSlot({ code }: { code: string }) {
    const { content, error } = useCmsContent('productDetails', undefined, 'ProductPage', code);

    if (error) return <ErrorBox errors={error} />;

    if (!content) return null;

    return (
        <div className="section section-4 related-articles">
            <div className="section__inner-container">
                <CMSSlot content={content} pagePosition="Section4" />
            </div>
        </div>
    );
}

function ValueSpan({ value, className }: { value: { readableValue?: string; formattedValue?: string } | undefined; className?: string }) {
    if (!value?.formattedValue) return null;

    return (
        <span className={className} aria-label={value.readableValue}>
            {value.formattedValue}
        </span>
    );
}
